<template>
  <v-col cols="12">
    <p class="grey-33">Account</p>
    <v-card elevation="0" outlined>
      <v-card-text>
        <h4 class="dark--text font-weight-medium fz-16">Numbers</h4>
<!--      v-on:focus="$event.target.select()"
          ref="clone" -->
        <p
          v-if="profile.number"
          class="mt-5 mb-0 grey-33 accountPhone d-inline-block"
        >
          {{ formatPhone(profile.number) }}
          <v-btn icon>
            <v-icon small @click="copyToClipboard">mdi-content-copy</v-icon>
          </v-btn>
        </p>
        <input type="hidden" id="copyCode" :value="profile.number" />
      </v-card-text>
      <v-card-actions
        class="mt-0 pa-4 flex-row"
        :class="{ 'justify-space-between': $vuetify.breakpoint.xs }"
      >
        <v-btn
          @click="$router.push('/select-number')"
          :disabled="profile.number > 0"
          color="primary"
          elevation="0"
          class="buttonBorderStyle bord px-4 px-sm-6 py-4"
          outlined
          >Change
        </v-btn>
        <!-- <v-btn color="primary" elevation="0" class="buttonBorderStyle px-4 px-sm-6 py-4" outlined>Transfer</v-btn> -->
        <v-btn
          @click="deleteNumberModal = !deleteNumberModal"
          :disabled="!profile.number"
          color="primary"
          elevation="0"
          class="buttonBorderStyle px-4 px-sm-6 py-4"
          outlined
          >Delete
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text>
        <h4 class="dark--text font-weight-medium fz-16">My devices</h4>
        <h5 class="font-weight-light">1 device</h5>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-layout align-center class="mb-1">
          <div class="icon-wrapper">
            <v-icon small color="primary">mdi-monitor</v-icon>
          </div>
          <h3 class="ms-3 font-weight-normal">Web</h3>
        </v-layout>

        <!-- <v-layout align-center>
          <div class="icon-wrapper">
            <v-icon small color="primary">mdi-cellphone</v-icon>
          </div>

          <div class="w-100 d-flex justify-space-between flex-row flex-wrap">
            <div class="d-flex flex-column ml-3">
              <h4 class="dark--text d-inline-block">iOS Device</h4>
              <p class="px-0 mb-0"></p>
            </div>

            <div>
              <v-btn
                @click="linkDialog = !linkDialog"
                :block="$vuetify.breakpoint.xs"
                color="primary"
                elevation="0"
                outlined
              >
                Reverify
              </v-btn>

              <v-btn class="px-1 font-weight-medium transparentButton">
                <v-img
                  :src="require('../../assets/svg/edit.svg')"
                  contain
                  width="24"
                  height="24"
                  max-width="min-content"
                  class="mx-auto"
                />
              </v-btn>

              <v-btn small icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-layout> -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <h4 class="dark--text font-weight-medium fz-16">Linked numbers</h4>
        <!-- <p v-if="getNumber.length">{{ getNumber.linked_number }}</p> -->
        <p class="mt-5">Use FreeFone with another phone number</p>
        <v-layout v-if="!linkedNumbers.length" class="my-8">
          Empty
        </v-layout>
        <template v-else>
          <v-layout
            v-for="(number, index) in linkedNumbers"
            :key="index + 'linked'"
            align-center
            class="my-8"
          >
            <div class="icon-wrapper">
              <v-icon small color="primary">mdi-cellphone</v-icon>
            </div>

            <div class="w-100 d-flex justify-space-between flex-row flex-wrap">
              <h4 class="dark--text d-inline-block mt-2 ml-3">
                {{ number.linked_number || 0 }}
              </h4>

              <div>
                <!-- <v-btn
                  class="mr-5"
                  @click="linkDialog = !linkDialog"
                  :block="$vuetify.breakpoint.xs"
                  color="primary"
                  elevation="0"
                  outlined
                >
                  Reverify
                </v-btn> -->

                <v-btn
                  small
                  icon
                  @click="openAssignedNumber(number)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-layout>
        </template>

        <v-btn
          @click="linkDialog = !linkDialog"
          :block="$vuetify.breakpoint.xs"
          color="primary"
          elevation="0"
          outlined
        >
          <v-icon left color="primary">mdi-plus</v-icon>
          New linked numbers
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="d-block d-sm-flex align-center justify-space-between flex-column">
        <div v-if="profileData.is_free_user || loadingUpgradePlan" class="d-flex w-100 justify-space-between flex-md-row flex-column">
          <div>
            <h4 class="dark--text">Current Plan</h4>
            <p class="px-0 mb-0">Free Plan</p>
          </div>
          <v-btn
            v-if="!loadingUpgradePlan"
            class="upgrade mt-3 mt-sm-0"
            :color="$vuetify.breakpoint.xs ? 'primary' : 'white'"
            elevation="0"
            :block="$vuetify.breakpoint.xs"
            :outlined="$vuetify.breakpoint.smAndUp"
            @click="upgradeDialog = true"
            >Upgrade
          </v-btn>
        </div>
        <div v-else class="w-100">
          <div
            class="d-flex w-100 justify-center flex-md-row flex-column align-center"
            style="gap:15px"
          >
            <div style="margin-right:auto">
              <h4 class="dark--text font-weight-medium fz-16">Current Plan</h4>
              <p v-if="tariffPlan" class="px-0 mb-0 d-flex flex-column">
                <span>{{ tariffPlan.plan_name }}</span>
                <span>{{ formatDateOnly(tariffPlan.expired_on) }}</span>
              </p>
              <!-- <p v-else class="px-0 mb-0">
                <template v-for="(plan, index) in planList">
                  <span :key="index">{{plan.plan_name}}</span>
                  <span v-if="index < planList.length - 1" :key="'camma' + index">, </span>
                </template>
              </p> -->
            </div>
            <v-btn
              v-if="profile && !profile.plan_uuid"
              class="upgrade align-self-sm-start"
              :color="$vuetify.breakpoint.xs ? 'primary' : 'white'"
              elevation="0"
              :block="$vuetify.breakpoint.xs"
              :outlined="$vuetify.breakpoint.smAndUp"
              @click="upgradeDialog = true"
              >Upgrade
            </v-btn>
            <v-btn
              :block="$vuetify.breakpoint.xs"
              class="upgradeSubscribe align-self-sm-start"
              color="error"
              elevation="0"
              outlined
              @click="downgradePlanDialog = true"
              >Unsubscribe
            </v-btn>
          </div>

          <v-divider class="my-4"></v-divider>

          <div>
            <h4 class="dark--text mb-3 font-weight-medium fz-16">Auto-renew</h4>
            <v-btn
              @click="autoRenewDialog = !autoRenewDialog"
              :block="$vuetify.breakpoint.xs"
              color="primary"
              elevation="0"
              outlined
            >
              <v-icon left color="primary">mdi-plus</v-icon>
              Connect auto-renew
            </v-btn>
          </div>

          <v-list class="py-0">
            <v-list-item class="px-0 py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-medium fz-16"
                  >Auto-renew</v-list-item-title
                >
                <v-list-item-subtitle
                  >Next payment 25.04.2023</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-switch @change="toggleAutoRenew"></v-switch>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
    </v-card>

    <!-- new linked number -->
    <v-dialog v-model="autoRenewDialog" width="512">
      <v-card class="overflow-auto" height="100%">
        <v-card-title class="body-1">
          Connect auto-renew
          <v-spacer></v-spacer>
          <v-btn @click="autoRenewDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <div class="form-wrapper">
          <v-form ref="creditCard">
            <v-card-title class="body-2"> Credit card </v-card-title>
            <div class="px-6">
              <v-text-field
                :value="profileSettings.number"
                :rules="cardRules"
                class="mb-2"
                ref="cardNumber"
                dense
                label="Credit card number"
                outlined
                maxlength="16"
                @keypress="onlyNumbers($event)"
                @input="setCardNumber"
              >
              </v-text-field>
              <div class="">
                <v-row>
                  <v-col class="py-0" cols="12" sm="6" md="3">
                    <v-select
                      v-model="profileSettings.exp_month"
                      :items="months"
                      item-text="label"
                      item-value="value"
                      menu-props="offsetY"
                      label="MM"
                      solo
                      dense
                      outlined
                    >
                      <template v-slot:append>
                        <v-icon size="15px">mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="6" md="3">
                    <v-select
                      v-model="profileSettings.exp_year"
                      :items="years"
                      menu-props="offsetY"
                      label="YYYY"
                      solo
                      dense
                      outlined
                    >
                      <template v-slot:append>
                        <v-icon size="15px">mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="py-0 offset-md-3">
                    <v-text-field
                      v-model="profileSettings.cvc"
                      :rules="cvvRule"
                      maxlength="3"
                      dense
                      label="CVV"
                      outlined
                      @keypress="onlyNumbers($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-card-title class="body-2"> Credit card </v-card-title>
            <div class="px-6">
              <v-row>
                <v-col class="py-2" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="profileSettings.city"
                    label="City"
                    hide-details
                    dense
                    outlined
                    @keypress="onlyLetters($event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-2" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="profileSettings.state"
                    label="State"
                    hide-details
                    dense
                    outlined
                    @keypress="onlyLetters($event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-2" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="profileSettings.billing_address_1"
                    label="Adress 1"
                    hide-details
                    dense
                    outlined
                    @keypress="onlyLettersAndNumbers($event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-2" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="profileSettings.billing_address_2"
                    label="Adress 2"
                    hide-details
                    dense
                    outlined
                    @keypress="onlyLettersAndNumbers($event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-2" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="profileSettings.zip_code"
                    label="ZIP/Code"
                    style="max-width: 120px"
                    hide-details
                    dense
                    outlined
                    @keypress="onlyNumbers($event)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-card-title class="body-2"> Personal information </v-card-title>
            <div class="px-6">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="profileSettings.firstName"
                    label="First name"
                    dense
                    outlined
                    @keypress="onlyLetters($event)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="profileSettings.lastName"
                    label="Last name"
                    dense
                    outlined
                    @keypress="onlyLetters($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- <v-card-title class="body-2"> Security code </v-card-title>
            <div class="px-6">
              <v-text-field
                dense
                label="Enter code"
                outlined
                v-model="profileSettings.securityCode"
              ></v-text-field>
            </div> -->
            <v-card-actions class="pb-5">
              <v-spacer></v-spacer>
              <v-btn
                small
                color="primary"
                class="px-5 py-5"
                elevation="0"
                outlined
                @click="autoRenewDialog = false"
                >Cancel
              </v-btn>
              <v-btn
                :disabled="autoRenewDisabled || incorrectCardNumber"
                small
                color="primary"
                class="px-5 py-5"
                elevation="0"
                @click="autoRenewApply"
              >Apply
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkDialog" width="500">
      <v-card>
        <v-card-title class="body-1 px-4" style="width: 100%!important;">
          Enter a number to link
          <v-spacer></v-spacer>
          <v-btn @click="linkDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="caption pb-6 px-4">
          Inbound calls to your Freefone Voice number will be forwarded to this
          number
        </v-card-text>
        <div class="px-md-8 px-4">
          <v-text-field
            :value="linkedNumberComputed"
            :maxlength="hasCountryCode ? 17 : 14"
            outlined
            @keypress="onlyNumbers"
            @input="setLinkedNumber"
          ></v-text-field>
        </div>

        <v-card-text class="caption px-4">
          Freefone Voice will send you a text message containing a 6-digit code.
          You can also verify by phone
          <!-- <a href="#" class="text-decoration-none info--text">verify by phone</a> -->
          .
        </v-card-text>

        <v-card-text v-if="linkedNumberError" class="caption px-4 red--text">
          {{ linkedNumberError }}
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            elevation="0"
            outlined
            height="36px"
            :width="isDesktop ? '90px' : '100%'"
            @click="linkDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="linkedNumber.length < (hasCountryCode ? 11 : 10)"
            small
            class="local-ml-0 local-mt-10"
            color="primary"
            height="36px"
            :width="isDesktop ? '112px' : '100%'"
            elevation="0"
            @click="sendCode"
            >Send code
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- OTP Dialog -->
    <v-dialog v-model="otp" width="500">
      <v-card>
        <v-card-title class="body-1">
          Enter the Verification Code
          <v-spacer></v-spacer>
          <v-btn @click="otp = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <div class="px-8 py-4">
          <v-otp-input v-model="otpNumber" length="6"></v-otp-input>
        </div>

        <v-card-text v-if="!otpReSendCodeSent">
          The code did not come,
          <a @click="sendCode(true)" class="text-decoration-none info--text"
            >re-send the code</a
          >.
        </v-card-text>
        <v-card-text v-else>
          The code has been sent.
          <br>
          If not received, please try again after {{ otpReSendCodeInterval }} seconds
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="otp = false"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="otpNumber.length < 6"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="verificationCode"
            >Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteNumberModal" width="512">
      <v-card>
        <v-card-title class="body-1">
          Delete {{ profile.number }}
          <v-spacer></v-spacer>
          <v-btn @click="deleteNumberModal = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="caption pb-6 px-6">
          Important: If your phone's voicemail is forwarded to Freefone Voice,
          switch back to your phone's voicemail before deleting your Freefone
          Voice number.
          <!-- <v-btn
            height="0"
            class="caption pa-0 text-capitalize"
            :ripple="false"
            plain
            text
            color="info"
          >
            Learn more
          </v-btn> -->
          <br />
          <br />
          When you delete your Freefone Voice number, you won't be able to receive
          calls and text messages on that number.
          <!-- <v-btn
            height="0"
            class="caption pa-0 text-capitalize"
            :ripple="false"
            plain
            text
            color="info"
          >
            Learn more
          </v-btn> -->
          <br />
          <br />
          You'll still be able to see the number's call history and messages.
          You can reclaim this number within 90 days if it's your only Freefone
          Voice number. You can change the number once per quarter.
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary darken-2"
            class="px-5 py-5 buttonBorderStyle"
            elevation="0"
            outlined
            @click="deleteNumberModal = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="danger"
            class="px-5 py-5 white--text"
            elevation="0"
            @click="deleteNumber"
            >Delete number
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Assigned number Dialog -->
    <v-dialog v-model="assignedNumberDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Delete assigned number
          <v-spacer></v-spacer>
          <v-btn @click="assignedNumberDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <!-- Are you want, to delete "{{ assignedNumber.linked_number || 0 }}"? -->
          Are you want, to delete "{{ assignedNumber ? assignedNumber.linked_number : '' }}"?
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="assignedNumberDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="deleteAssignedNumber"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Upgrade plan dialog -->
    <v-dialog v-model="upgradeDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Upgrade plan
          <v-spacer></v-spacer>
          <v-btn @click="upgradeDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="upgradePayload.plan_uuid"
            :items="plans"
            item-text="plan_name"
            item-value="plan_uuid"
            menu-props="offsetY"
            solo
            dense
            outlined
          >
            <template v-slot:append>
              <v-icon size="15px">mdi-chevron-down</v-icon>
            </template>
          </v-select>

          <div class="upgrade-plan-dialog-row">
            <v-select
              v-model="upgradePayload.duration"
              :items="durationOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              solo
              dense
              outlined
              label="Duration"
            >
              <template v-slot:append>
                <v-icon size="15px">mdi-chevron-down</v-icon>
              </template>
            </v-select>

            <v-select
              v-model="upgradePayload.duration_unit"
              :items="durationUnits"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              solo
              dense
              outlined
            >
              <template v-slot:append>
                <v-icon size="15px">mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </div>

          <div class="d-flex justify-end w-100 mt-2 text--black font-weight-bold text-h6" v-if="upgradePayload.duration >= 1">
            <div class="mr-2">Total:</div>
            <div>${{ totalPlanPrice }}</div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="upgradeDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="upgradePayload.duration < 1"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            :loading="loadingUpgradePlanDialog"
            @click="upgradeTariffPlan"
            >Upgrade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Downgrade plan dialog -->
    <v-dialog v-model="downgradePlanDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Downgrade plan
          <v-spacer></v-spacer>
          <v-btn @click="downgradePlanDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <!-- Are you want, to delete "{{ assignedNumber.linked_number || 0 }}"? -->
          Are you want, to downgrade tariff plan?
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="downgradePlanDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="downgradeTariffPlan"
            >Downgrade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'
import { formatPhone, beautifyNumber } from '../../utilities/formatPhone'

export default {
  name: 'settingsAccount',
  props: ['profileData'],
  data: () => {
    return {
      loadingUpgradePlan: false,
      loadingUpgradePlanDialog: false,
      profile: {},
      linkDialog: false,
      linkedNumber: '',
      linkedNumbers: [],
      otp: false,
      otpReSendCodeSent: false,
      otpReSendCodeInterval: 0,
      otpReSendCodeIntervalIdx: null,
      deleteNumberModal: false,
      upgrade: true,
      autoRenewDialog: false,
      autoRenewApplyFinish: true,
      assignedNumberDialog: false,
      assignedNumber: null,
      otpNumber: '',
      verificationUuid: '',
      profileSettings: {
        number: '',
        exp_month: 1,
        exp_year: 0,
        cvc: '',
        city: '',
        state: '',
        billing_address_1: '',
        billing_address_2: '',
        zip_code: '',
        firstName: '',
        lastName: ''
        // securityCode: ''
      },
      planList: [],
      durationUnits: [
        { label: 'Month', value: 'month' },
        { label: 'Year', value: 'year' }
      ],
      upgradeDialog: false,
      upgradePayload: {
        plan_uuid: '',
        duration_unit: 'month',
        duration: 1
      },
      downgradePlanDialog: false,
      tariffPlan: null,
      cvvRule: [
        v => !!v.toString() || 'CVV is required',
        v => v.toString().length === 3 || 'CVV should be 3 digits'
      ],
      cardRules: [
        v => !!v || 'Card number is required',
        v => /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test((v.replace(/\s+/g, ''))) || 'Card number must be valid'
      ],
      disableAutoRecharge: false,
      linkedNumberError: ''
    }
  },
  computed: {
    totalPlanPrice () {
      if (this.planList.length === 0) return 0
      const du = this.upgradePayload.duration_unit === 'month' ? 'plan_monthly_price' : 'plan_yearly_price'
      const d = this.upgradePayload.duration || 0
      const plan = this.planList.find(item => item.plan_uuid === this.upgradePayload.plan_uuid)
      return plan[du] * d
    },
    plans () {
      if (this.profile) {
        return this.planList.filter(item => !item.plan_name.toLowerCase().includes('free') && item.plan_uuid !== this.profile.plan_uuid)
      }
      return this.planList
    },
    months () {
      const months = []
      for (let i = 1; i < 13; i++) {
        months.push({
          label: i < 10 ? '0' + i : i,
          value: i
        })
      }
      return months
    },
    years () {
      const year = new Date().getFullYear()
      const years = []
      for (let i = year; i < year + 10; i++) {
        years.push(i)
      }
      return years
    },
    autoRenewDisabled () {
      return Object.values(this.profileSettings).some(e => !e) || !this.$refs.creditCard.validate()
    },
    isDesktop () { return this.$vuetify.breakpoint.mdAndUp },
    hasCountryCode () {
      try {
        const cleaned = `${this.linkedNumber}`
        const match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
        return !!match[1]
      } catch (err) {
        return false
      }
    },
    linkedNumberComputed () {
      return this.beautifyNumber(this.linkedNumber)
    },
    durationOptions () {
      return Array.from({ length: this.upgradePayload.duration_unit === 'month' ? 11 : 10 }, (value, index) => ({ label: index + 1, value: index + 1 }))
    }
  },
  created() {
    this.loadLinkedNumber()
    this.loadPlans()
    this.loadSubscriptions()
    this.profileSettings.exp_year = new Date().getFullYear()
    this.checkCards()
  },
  beforeDestroy() {
    if (this.otpReSendCodeIntervalIdx) {
      clearInterval(this.otpReSendCodeIntervalIdx)
      this.otpReSendCodeSent = false
    }
  },
  methods: {
    ...mapActions('numbers', ['getLinkedNumber', 'sendLinkedNumber', 'deleteLinkedNumber', 'verifyLinkedNumber', 'releaseNumber']),
    ...mapActions(['showAlert', 'getPlanList', 'upgradePlan', 'downgradePlan', 'autoRenewCard', 'autoRenew', 'getSubscriptions', 'getAutoChargeCards']),
    formatPhone,
    beautifyNumber,
    filter(evt) {
      const expect = evt.target.value.toString() + evt.key.toString()

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    copyTestingCode() {
      const testingCodeToCopy = document.querySelector('#copyCode')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      document.execCommand('copy')

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    copyToClipboard() {
      const el = document.createElement('textarea')
      el.value = this.profile.number
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    async sendCode(isResend = false) {
      this.linkedNumberError = ''
      try {
        const formattedLinkedNumber = this.linkedNumber.length === 10 ? `1${this.linkedNumber}` : this.linkedNumber
        const data = await this.sendLinkedNumber({ linked_number: formattedLinkedNumber })
        // console.log(data, 'data data data')
        this.verificationUuid = data.success

        this.linkDialog = false
        this.otp = true
        if (isResend) {
          this.otpReSendCodeSent = true
          this.otpReSendCodeInterval = 60
          this.otpReSendCodeIntervalIdx = setInterval(() => {
            if (this.otpReSendCodeInterval - 1 === 0) {
              this.otpReSendCodeInterval = 0
              clearInterval(this.otpReSendCodeIntervalIdx)
              this.otpReSendCodeSent = false
              return
            }
            this.otpReSendCodeInterval--
          }, 1000)
        }
      } catch (err) {
        this.linkedNumberError = (Array.isArray(err.message)) ? err.message[0] : isNaN(err.error) ? err.error : err.message
        throw new Error(err)
      }
    },
    async loadLinkedNumber() {
      try {
        const data = await this.getLinkedNumber()
        this.linkedNumbers = data
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadPlans() {
      try {
        this.loadingUpgradePlan = true
        const data = await this.getPlanList()
        if (!data || !data.data || !data.data.length) return
        this.planList = data.data
        const vipPlan = data.data.find(item => item.plan_name?.toLowerCase()?.includes('vip'))
        if (vipPlan) {
          this.upgradePayload.plan_uuid = vipPlan.plan_uuid
          return
        }
        this.upgradePayload.plan_uuid = data.data[0].plan_uuid
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loadingUpgradePlan = false
      }
    },
    async loadSubscriptions() {
      if (this.profileData.is_free_user === undefined) {
        setTimeout(() => {
          this.loadSubscriptions()
        }, 1000)
      } else if (!this.profileData.is_free_user) {
        try {
          const data = await this.getSubscriptions()
          if (!data) return
          this.tariffPlan = data.plan
        } catch (err) {
          throw new Error(err)
        }
      }
    },
    async upgradeTariffPlan () {
      try {
        this.loadingUpgradePlanDialog = true
        const data = await this.upgradePlan(this.upgradePayload)
        if (!data || !data.url) return
        window.location.href = data.url
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loadingUpgradePlanDialog = false
        this.upgradeDialog = false
      }
    },
    async downgradeTariffPlan () {
      try {
        const data = await this.downgradePlan()
        if (!data) return
        this.$emit('reloadProfile')
        this.showAlert({ message: 'Tariff plan was downgraded' })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.downgradePlanDialog = false
      }
    },
    async autoRenewApply() {
      try {
        const data = await this.autoRenewCard(this.profileSettings)
        if (!data) return

        this.showAlert({ message: 'Charge card was successfully added' })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.autoRenewDialog = false
      }
    },
    async checkCards () {
      try {
        const data = await this.getAutoChargeCards()
        if (!data || !data.data || !data.data.length) this.disableAutoRecharge = true
      } catch (err) {
        console.log('API: ', (err?.message || err))
      }
    },
    async toggleAutoRenew (e) {
      try {
        if (this.disableAutoRecharge) return this.showAlert({ error: 'Please add card first' })
        await this.autoRenew(e)
        this.showAlert({ message: `Auto renew has ${e ? 'enabled' : 'disabled'}` })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    async verificationCode() {
      try {
        await this.verifyLinkedNumber({
          verification_uuid: this.verificationUuid,
          code: this.otpNumber
        })
        this.loadLinkedNumber()
        this.showAlert({ message: 'Linked number was successfully verified!' })
      } catch (err) {
        let error = (Array.isArray(err.message)) ? err.message[0] : typeof err.message === 'number' ? err.error : err.message
        if (error === 'Internal server error') error = 'Incorrect Verification Code.'
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.otp = false
        this.linkedNumber = ''
        this.otpNumber = ''
      }
    },
    async deleteNumber() {
      try {
        await this.releaseNumber()
        this.showAlert({ message: 'Number released successfully', callback: 'releaseNumber' })
      } catch (err) {
        throw new Error(err)
      } finally {
        this.deleteNumberModal = false
      }
    },
    openAssignedNumber (number) {
      this.assignedNumber = number
      this.assignedNumberDialog = true
    },
    async deleteAssignedNumber() {
      try {
        if (!this.assignedNumber || !this.assignedNumber.linked_number) return

        const data = await this.deleteLinkedNumber(this.assignedNumber.linked_number)
        if (!data) return

        this.loadLinkedNumber()
        this.showAlert({ message: 'Linked number was deleted' })
      } catch (err) {
        throw new Error(err)
      } finally {
        this.assignedNumber = null
        this.assignedNumberDialog = false
      }
    },
    onlyNumbers(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },
    formatDateOnly (date) {
      return this.formatDate(date).split(' ')[0]
    },
    onlyLetters (event) {
      if (!/^[A-Za-z .,]+$/.test(event.key)) return event.preventDefault()
    },
    setLinkedNumber (e) {
      this.linkedNumber = e.replace(/\D/g, '')
    },
    setCardNumber (e) {
      this.profileSettings.number = e.replace(/\D/g, '')
      const inputEl = this.$refs.cardNumber.$el.querySelector('input')
      setTimeout(() => {
        inputEl.value = this.profileSettings.number
      }, 50)
    }
  },
  watch: {
    upgradeDialog() {
      this.upgradePayload.duration = 1
      this.upgradePayload.duration_unit = 'month'
    },
    profileData(newVal) {
      if (newVal) {
        this.profile = this.profileData
      }
    },
    linkDialog () {
      this.linkedNumberError = ''
      if (this.otp) return
      this.linkedNumber = ''
    },
    'upgradePayload.duration_unit' () {
      this.upgradePayload.duration = 1
    }
  }
}
</script>

<style scoped>
.upgrade {
  background-color: #5758b0 !important;
  border-color: #5758b0 !important;
  color: white;
}

.upgradeSubscribe {
  border-color: #e3e3e3 !important;
}

.buttonBorderStyle {
  border: 1px solid #e3e3e3;
}

.accountPhone {
  font-size: 16px;
}

.icon-wrapper {
  background-color: rgba(127, 165, 232, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-weight-normal {
  font-weight: 400;
}

.transparentButton {
  background: transparent !important;
  box-shadow: none !important;
}

.upgrade-plan-dialog-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.form-wrapper {
  overflow-y: auto;
  max-height: 80vh;
}

@media (max-width: 960px) {
  ::v-deep .v-card__actions  {
      display: flex;
      flex-wrap:wrap;
      height: auto;
  }
  .local-ml-0{
    margin-left: 0!important;
  }
  .local-mt-10{
    margin-top: 10px
  }
}
</style>
