<template>
  <v-container fluid class="pa-0 mt-8">
    <Navbar />
    <alert-status v-if="$store.state.alertStatus !== null" />
    <v-row class="ma-0">
      <v-col v-if="!other" cols="12" md="5" class="mx-auto px-5">
        <v-btn
          :to="{ name: 'Settings' }"
          color="primary"
          class="mb-10"
          outlined
        >
          <v-icon left> mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <h4 class="body-1">
          Select who you want to apply the rule to
        </h4>
        <p class="mt-3 muted--text">
          Manage FreeFone labels and contact numbers at
          <a href="https://freefone.app" class="info--text text-decoration-none"
            >Freefone</a
          >. If you change a number in FreeFone Contacts, be sure to update your
          rules to use the new number.
        </p>
        <div class="d-flex align-center" style="height:36px">
          <v-text-field
            v-model="search"
            filled
            prepend-inner-icon="mdi-magnify"
            class="search-input"
            hide-details
            solo
            dense
            height="auto"
            label="Labels numbers"
          ></v-text-field>
          <span v-if="selected.length" class="ml-4 fz-14">{{ selected.length }} selected</span>
          <v-btn
            :disabled="!selectedType"
            height="36px"
            width="77px"
            color="white"
            class="white--text ms-4 px-8 next-button"
            @click="other = true"
            >Next
          </v-btn>
        </div>
        <div v-if="selectedType">
          <v-subheader class="pa-0 dark--text font-weight-bold mt-7"
            >Selected</v-subheader
          >
          <v-card elevation="0" outlined :min-width="$vuetify.breakpoint.smAndUp ? '535' : 'unset'">
            <v-list subheader two-line flat>
              <v-list-item v-if="otherSelected">
                <v-list-item-action class="me-3">
                  <v-btn icon @click="otherSelected = null">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-avatar
                  class="me-3 d-flex justify-center align-center"
                >
                  <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                </v-list-item-avatar>
                <div class="d-flex justify-space-between align-center w-100 flex-column flex-sm-row">
                  <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 py-0 pr-0">
                    <v-list-item-title v-if="otherSelected === 'anonymous'">Anonymous callers</v-list-item-title>
                    <v-list-item-title v-else-if="otherSelected === 'user_spam'">Spam Callers</v-list-item-title>
                    <v-list-item-title v-else-if="otherSelected === 'system_spam'">Spam Callers of all Freefone user</v-list-item-title>
                  </div>
                  <div class="col-sm-9 col-md-8  justify-content-md-start  col-lg-8 col-xl-8 py-0 pr-0 offset-md-1">
                    <v-list-item-subtitle v-if="otherSelected === 'anonymous'" class="text-font-size-add-rule text-sm-right text-md-right text-lg-right">
                      <span>The callers hide their numbers and name</span>
                      </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
              <v-list-item-group v-else v-model="settings" multiple>
                <v-list-item
                  v-for="(item, index) in selected"
                  :key="index"
                  @click="uncheckSelected(index, item)"
                >
                  <template>
                    <v-list-item-action class="me-3">
                      <v-checkbox
                        v-model="item.active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-avatar
                      class="me-3 d-flex justify-center align-center"
                    >
                      <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <div class="row flex-lg-row align-center">
                      <div  class="col-sm-3 col-md-3  col-lg-3 col-xl-3  py-0 pr-0">
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </div>
                      <div class="col-sm-9 col-md-8  justify-content-md-start  col-lg-8 col-xl-8 py-0 pr-0 ml-0 offset-md-1">
                        <!-- <v-list-item-subtitle class="text-font-size-add-rule text-sm-right text-md-right text-lg-right">{{
                          item.description
                          }}</v-list-item-subtitle> -->
                      </div>
                    </div>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>

          <v-subheader class="pa-0 dark--text font-weight-medium mt-7"
            >Labels ({{ labels.length }})</v-subheader
          >
          <v-card
            v-if="labels.length"
            :min-width="$vuetify.breakpoint.smAndUp ? '535' : 'unset'"
            elevation="0"
            outlined
            class="mb-7"
          >
            <v-list subheader two-line flat>
              <v-list-item-group multiple>
                <v-list-item
                  v-for="(item, index) in computedLabels"
                  :key="index"
                  @click="changeLabel(index, item)"
                >
                  <template>
                    <v-list-item-action class="me-3">
                      <v-checkbox v-model="item.active" color="primary"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-avatar
                      class="me-3 d-flex justify-center align-center"
                    >
                      <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <!--<div :class="{'d-flex': window.width > 466.99}">
                      <v-list-item-title :class="{'d-flex-wrap': window.width > 466.99}">{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle :class="{'d-flex-wrap': window.width > 466.99}" class="text-end">{{
                        item.description
                        }}</v-list-item-subtitle>
                    </div>-->
                    <div class="d-flex justify-space-between align-center w-100 d-block flex-column flex-sm-row">
                      <div  class="col-sm-3 col-md-3  col-lg-3 col-xl-3  py-0 pr-0">
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </div>
                      <!-- <div class="col-sm-9 col-md-8  justify-content-md-start  col-lg-8 col-xl-8 py-0 pr-0 offset-md-1">
                        <v-list-item-subtitle class="text-font-size-add-rule text-sm-right text-md-right text-lg-right">{{
                          item.description
                          }}</v-list-item-subtitle>
                      </div> -->
                    </div>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>

        <v-subheader class="pa-0 dark--text font-weight-medium mt-0"
          >Other</v-subheader
        >

        <v-card elevation="0" outlined :min-width="$vuetify.breakpoint.smAndUp ? '535' : 'unset'">
          <v-list subheader two-line flat>
            <v-radio-group
              v-model="otherSelected"
              hide-details
              class="mt-5 pt-0"
            >
              <v-list-item @click="otherSelected = 'anonymous'">
                <v-list-item-action class="me-3">
                  <v-radio
                    value="anonymous"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-avatar
                  class="me-3 d-flex justify-center align-center"
                >
                  <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                </v-list-item-avatar>
                <div class="d-flex justify-space-between align-center w-100 flex-column flex-sm-row">
                  <div  class="col-sm-3 col-md-3  col-lg-3 col-xl-3  py-0 pr-0">
                    <v-list-item-title>Anonymous callers</v-list-item-title>
                  </div>
                  <div class="col-sm-9 col-md-8  justify-content-md-start  col-lg-8 col-xl-8 py-0 pr-0 offset-md-1">
                    <v-list-item-subtitle class="text-font-size-add-rule text-sm-right text-md-right text-lg-right">
                      The callers hide their numbers and name
                      </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
              <v-list-item @click="otherSelected = 'user_spam'">
                <v-list-item-action class="me-3">
                  <v-radio
                    value="user_spam"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-avatar
                  class="me-3 d-flex justify-center align-center"
                >
                  <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                </v-list-item-avatar>
                <div class="d-flex justify-space-between align-center w-100 flex-column flex-sm-row">
                  <div class="col-sm-3 col-md-3  col-lg-3 col-xl-3  py-0 pr-0">
                    <v-list-item-title>Spam Callers</v-list-item-title>
                  </div>
                </div>
              </v-list-item>
              <v-list-item @click="otherSelected = 'system_spam'">
                <v-list-item-action class="me-3">
                  <v-radio
                    value="system_spam"
                    color="primary"
                  />
                </v-list-item-action>
                <v-list-item-avatar
                  class="me-3 d-flex justify-center align-center"
                >
                  <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                </v-list-item-avatar>
                <div class="d-flex justify-space-between align-center w-100 flex-column flex-sm-row">
                  <div  class="col-sm-3 col-md-3  col-lg-3 col-xl-3  py-0 pr-0">
                    <v-list-item-title>Spam Callers of all Freefone user</v-list-item-title>
                  </div>
                </div>
              </v-list-item>
            </v-radio-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col v-else cols="12" md="5" class="mx-auto">
        <v-btn color="primary" class="mb-10" outlined @click="other = false; pauseRecord()">
          <v-icon left> mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <v-card :loading="loading" style="min-height: 4px; box-shadow: none !important">
          <template v-if="!loading">
            <h4 class="body-1 font-weight-medium">When these contacts call you</h4>
            <v-list-item v-if="otherSelected" class="mt-4 p4-0 px-0">
              <v-list-item-avatar
                class="me-3 mr-3 d-flex justify-center align-center"
              >
                <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="otherSelected === 'anonymous'">Anonymous callers</v-list-item-title>
                <v-list-item-title v-else-if="otherSelected === 'user_spam'">Spam Callers</v-list-item-title>
                <v-list-item-title v-else-if="otherSelected === 'system_spam'">Spam Callers of all Freefone user</v-list-item-title>
                <v-list-item-subtitle v-if="otherSelected === 'anonymous'" class="text-font-size-add-rule">
                  <span>The callers hide their numbers and name</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-btn color="primary" outlined @click="other = false">Change</v-btn>
            </v-list-item>
            <div v-else class="d-flex align-center justify-space-between">
              <div class="d-flex flex-wrap">
                <div v-for="(label, index) in selected" :key="index" class="w-max d-flex mr-4">
                  <v-list-item-avatar
                    class="me-3 mr-3 d-flex justify-center align-center"
                  >
                    <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="w-max">
                    <v-list-item-title class="font-weight-medium w-max">{{label.label}}</v-list-item-title>
                  </v-list-item-content>
                </div>
              </div>
              <v-btn color="primary" outlined @click="other = false">Change</v-btn>
            </div>
            <v-radio-group
              mandatory
              hide-details
              class="mt-5 pt-0"
              v-model="radioDetect"
            >
              <v-list class="transparent py-0 pt-0">
                <v-list-item class="d-flex flex-column align-start px-0">
                  <v-radio value="all">
                    <template v-slot:label>
                      <span class="grey-33">Forward calls</span>
                    </template>
                  </v-radio>
                  <v-radio value="user_spam">
                    <template v-slot:label>
                      <span class="grey-33">Send to voicemail</span>
                    </template>
                  </v-radio>
                </v-list-item>
              </v-list>
            </v-radio-group>
            <div v-if="radioDetect === 'all'">
              <h4 class="body-2 font-weight-bold">Ring these devices</h4>
              <v-list-item>
                <v-list-item-avatar class="me-3 d-flex justify-center align-center">
                  <v-img
                    :src="require('../assets/svg/ring-device.svg')"
                    contain
                    width="40"
                    height="40"
                    max-width="min-content"
                    class="my-6 mx-auto"
                  />
                </v-list-item-avatar>
                <v-card-text class="caption gray-44 d-flex align-center">
                  Devices turned on under "My devices" in
                  <v-btn
                    :to="{ name: 'Settings', params: { ref: 'settingsCalls' } }"
                    color="primary"
                    class="text-capitalize pa-0 ml-2"
                    text
                    small
                    elevation="0"
                  >
                    Settings
                  </v-btn>
                </v-card-text>
              </v-list-item>
              <h4 class="body-2 mt-3 font-weight-bold">Forward to these numbers</h4>
              <v-card-text v-if="!linkedNumbers.length" class="caption gray-44 px-0 py-2"
                >No linked numbers</v-card-text
              >
              <v-radio-group
                v-else
                v-model="linkedNumber"
                mandatory
                hide-details
                class="mt-5 pt-0"
              >
                <v-layout
                  v-for="(number, index) in linkedNumbers"
                  :key="index + 'linked'"
                  align-center
                  class="my-2"
                >
                  <v-radio
                    :value="number.uuid"
                    color="primary"
                    :label="number.linked_number"
                  ></v-radio>
                </v-layout>
              </v-radio-group>
              <div style="width: fit-content">
                <h4 class="body-2 mt-3 font-weight-bold">
                  If the call isn't answered, play this voicemail greeting
                </h4>
                <div class="mt-2 d-flex align-center" style="width: 384px">
                  <v-select
                    v-model="notAnswered.value"
                    class="autoRechargeSelect"
                    outlined
                    hide-details
                    :items="greetingsOptions"
                    item-text="label"
                    item-value="value"
                    :menu-props="{ contentClass: 'settings-select' }"
                    width="fit-content"
                    single-line
                    solo
                    dense
                    label="Freefone Voice default"
                    @change="setDuration($event, 'notAnswered')"
                  />
                  <div class="d-flex align-center ml-2" style="width: 63px" v-if="recording">
                    <v-btn :loading="loadingRecording" icon @click="play">
                      <v-img
                        :src="require(`../assets/svg/${!isPlayed ? 'play_arrow' : 'pause'}.svg`)"
                        class="cursor-p"
                        contain
                        width="24"
                        height="24"
                        max-width="min-content"
                      />
                    </v-btn>
                    <v-card-text class="caption gray-44 px-0 py-0 ml-2"
                      >{{ formatTime(notAnswered.duration) }}</v-card-text
                    >
                  </div>
                </div>
                <div class="mt-7">
                  <h4 class="body-2 mt-3 font-weight-bold">Screen the call</h4>
                  <v-select
                    v-model="screenCall"
                    style="width: 313px"
                    class="autoRechargeSelect mt-2"
                    outlined
                    hide-details
                    :items="screenCallOptions"
                    item-text="label"
                    item-value="value"
                    :menu-props="{ contentClass: 'settings-select' }"
                    width="fit-content"
                    single-line
                    solo
                    dense
                    label="No preference"
                  />
                </div>
              </div>
            </div>
            <div v-else-if="radioDetect === 'user_spam'">
              <h4 class="body-2 mt-3 font-weight-bold">
                Play this voicemail greeting
              </h4>
              <div class="mt-2 d-flex align-center" style="width: 380px">
                <v-select
                  v-model="playVoicemail.value"
                  class="autoRechargeSelect"
                  outlined
                  hide-details
                  :items="greetingsOptions"
                  item-text="label"
                  item-value="value"
                  :menu-props="{ contentClass: 'settings-select' }"
                  width="fit-content"
                  single-line
                  solo
                  dense
                  label="Freefone Voice default"
                  @change="setDuration($event, 'playVoicemail')"
                />
                <div class="d-flex align-center ml-3" v-if="recording">
                  <v-btn :loading="loadingRecording" icon @click="play">
                    <v-img
                      :src="require(`../assets/svg/${!isPlayed ? 'play_arrow' : 'pause'}.svg`)"
                      class="cursor-p"
                      contain
                      width="24"
                      height="24"
                      max-width="min-content"
                    />
                  </v-btn>
                  <v-card-text class="caption gray-44 px-0 py-0 ml-2"
                    >{{ formatTime(playVoicemail.duration) }}</v-card-text
                  >
                </div>
              </div>
            </div>
            <v-tooltip top content-class="v-tooltip--top" :disabled="isSelectedVoicemailGreating">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  :disabled="!isSelectedVoicemailGreating"
                  color="white"
                  class="px-5 py-5 blueButton mt-8 button"
                  elevation="0"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="saveOption"
                >
                  Save
                </v-btn>
              </template>
              <span>Please record the voicemail greeting</span>
            </v-tooltip>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <audio
      v-if="recording"
      :src="recording"
      ref="audio"
      @ended="pauseRecord"
    ></audio>
  </v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import resizeMixin from '@/mixins/resize.mixin'
import AlertStatus from '@/components/Alert'
import config from '@/_services/config.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [resizeMixin],
  name: 'addRule',
  components: {
    Navbar,
    AlertStatus
  },
  data: () => {
    return {
      loading: false,
      deleteRuleSection: false,
      radioDetect: 'all',
      settings: [],
      voicemailNumber: [
        {
          name: '2'
        },
        {
          name: '3'
        }
      ],
      labels: [],
      selected: [],
      linkedNumber: '',
      linkedNumbers: [],
      other: false,
      screenWidth: null,
      search: '',
      screenCall: true,
      screenCallOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      notAnswered: {
        value: '',
        duration: '',
        url: ''
      },
      playVoicemail: {
        value: '',
        duration: '',
        url: ''
      },
      isPlayed: false,
      otherSelected: null,
      recording: null,
      loadingRecording: false
    }
  },
  computed: {
    ...mapGetters('voicemails', ['voicemailsGreeting']),
    // ...mapGetters('labels', ['labels']),
    isSelectedVoicemailGreating () {
      if (this.radioDetect === 'all') {
        return !!this.notAnswered.value
      }
      return !!this.playVoicemail.value
    },
    isEdit () {
      return this.$route.name === 'EditRule'
    },
    greetingsOptions() {
      return this.voicemailsGreeting.map(item => ({
        value: item.uuid,
        label: item.name,
        duration: item.duration
      }))
    },
    computedLabels () {
      if (!this.search) return this.labels
      return this.labels.filter(item => item.label.toLowerCase().includes(this.search.toLowerCase()))
    },
    selectedType () {
      return this.selected.length || this.otherSelected
    }
  },
  watch: {
    otherSelected (val) {
      if (val) {
        this.loadLabels()
        this.selected = []
      }
    },
    'playVoicemail.value' () {
      this.pauseRecord()
      this.getRecordFile()
    },
    'notAnswered.value' () {
      this.pauseRecord()
      this.getRecordFile()
    },
    radioDetect () {
      this.pauseRecord()
      this.getRecordFile()
    }
  },
  async created () {
    if (this.isEdit) {
      this.loading = true
    }
    await this.getVoicemailsGreeting()
    await this.loadLabels()
    await this.loadLinkedNumber()
    if (this.isEdit) return this.loadRule()
    this.loading = false
    const defaultVoicemail = this.voicemailsGreeting.find(e => e.is_default)
    this.setVoicemail(defaultVoicemail)
  },
  beforeDestroy() {
    this.pauseRecord()
  },
  mounted() {
    window.addEventListener('resize', this.getScreenWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.getScreenWidth)
  },
  methods: {
    ...mapActions('voicemails', ['getVoicemailsGreeting', 'getGreetingFile']),
    ...mapActions('labels', ['getLabels']),
    ...mapActions('numbers', ['getLinkedNumber', 'getNumbers']),
    ...mapActions(['postRule', 'showAlert', 'getRuleByUuid', 'patchRule']),
    pauseRecord () {
      if (!this.$refs.audio) return
      this.$refs.audio.pause()
      this.isPlayed = false
      this.$refs.audio.currentTime = 0
    },
    play () {
      if (this.isPlayed) {
        this.pauseRecord()
        return
      }
      setTimeout(() => {
        this.$refs.audio.play()
        this.isPlayed = true
      }, 100)
    },
    async loadLabels () {
      const data = await this.getLabels()
      this.labels = data.map(item => ({
        ...item,
        active: false
      }))
    },
    async loadLinkedNumber() {
      try {
        const data = await this.getLinkedNumber()
        if (!data || !data.length) return
        this.linkedNumbers = data
        this.linkedNumber = data[0].uuid
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadRule () {
      if (!this.$route.params.uuid) return
      try {
        const data = await this.getRuleByUuid(this.$route.params.uuid)
        if (!data) return

        this.otherSelected = data.number_type === 'all' ? null : data.number_type
        this.radioDetect = data.route_to_voicemail ? 'user_spam' : 'all'
        this.linkedNumber = data.linked_number_uuid
        this.screenCall = data.enable_screening
        const voicemail = this.voicemailsGreeting.find(e => e.uuid === data.media_uuid)
        this.setVoicemail(voicemail)
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    setVoicemail (voicemail) {
      if (!voicemail) return
      this.notAnswered = {
        value: voicemail.uuid,
        duration: voicemail.duration
      }
      this.playVoicemail = {
        value: voicemail.uuid,
        duration: voicemail.duration
      }
    },
    getScreenWidth() {
      this.screenWidth = window.screen.width
    },
    async saveOption() {
      try {
        this.pauseRecord()
        const userProfile = localStorage.getItem('userProfile')
        if (userProfile.is_free_user) return
        const numbers = await this.getNumbers()
        if (!numbers || !numbers.data || !numbers.data.length) return
        const body = {
          number_uuid: numbers.data[0].uuid
        }
        if (this.radioDetect === 'all') {
          if (!this.linkedNumber) return this.showAlert({ error: 'Please select linked number' })
          body.media_uuid = this.notAnswered.value
          body.route_to_linked_number = true
          body.route_to_voicemail = false
          body.linked_number_uuid = this.linkedNumber
          body.enable_screening = this.screenCall
        } else {
          body.media_uuid = this.playVoicemail.value
          body.route_to_voicemail = true
          body.route_to_linked_number = false
        }
        const payload = this.isEdit
          ? { uuid: this.$route.params.uuid, body }
          : { numberType: this.otherSelected ? this.otherSelected : this.radioDetect, body }
        const API = this.isEdit ? 'patchRule' : 'postRule'
        const data = await this[API](payload)
        if (!data) return
        this.showAlert({ message: `Rule ${this.isEdit ? 'saved' : 'added'}` })
        this.$router.push({ name: 'ManageRule' })
      } catch (err) {
        if (err.message.includes('already exist')) {
          const error = this.otherSelected === 'anonymous'
            ? 'Rule for anonymous caller ID already exists.'
            : err.message.replace('number_type:', '')
          this.showAlert({ error })
          return
        }

        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    deleteRule() {
      this.deleteRuleSection = true
    },
    async changeLabel(key, label) {
      if (this.otherSelected) {
        await this.loadLabels()
        this.otherSelected = null
        this.selected = []
      }
      label.active = true
      this.selected.push(label)
      this.labels = this.labels.filter(item => item.label !== label.label)
    },
    async getRecordFile () {
      try {
        this.loadingRecording = true
        const uuid = this.radioDetect === 'all' ? this.notAnswered.value : this.playVoicemail.value
        const greeting = this.voicemailsGreeting.find(item => item.uuid === uuid)
        if (!greeting) return ''
        const greetingName = greeting.greeting_url.split('/voicemail_greeting/file/')[1]
        if (!greetingName) return
        const fileType = greetingName.split('.')[1]
        const data = await this.getGreetingFile(greetingName)
        const type = `audio/${fileType === 'mp3' ? 'mpeg-3' : fileType}`
        const recordingBlob = new Blob([data], { type })
        this.recording = URL.createObjectURL(recordingBlob)
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loadingRecording = false
      }
    },
    uncheckSelected(idx, label) {
      label.active = false
      this.selected = this.selected.filter(item => item.label !== label.label)
      this.labels.push(label)
    },
    setDuration (event, variable) {
      const voicemail = this.voicemailsGreeting.find(e => e.uuid === event)
      if (!voicemail) return
      this[variable].duration = voicemail.duration
    },
    formatTime (time) {
      time = parseInt(time)
      const m = parseInt(time / 60)
      const s = time % 60
      return `${m}:${s < 10 ? `0${s}` : s}`
    }
  }
}
</script>

<style>
.blueButton {
  background-color: #5758b0 !important;
  border-color: #5758b0 !important;
}

.transparentButton {
  background: transparent !important;
  box-shadow: none !important;
}
@media (max-width: 390px) {
  .text-font-size-add-rule{
    font-size: 13px!important;
  }
}
@media (max-width: 370px) {
  .text-font-size-add-rule{
    font-size: 12px!important;
  }
}
@media (max-width: 347px) {
  .text-font-size-add-rule{
    font-size: 11px!important;
  }
}
</style>

<style scoped>
  .v-list-item__title, .v-list-item__subtitle {
    overflow: unset !important;
    text-overflow: unset !important;
  }
  .next-button {
    background: #5758B0 !important;
  }
  .next-button:disabled {
    color: white !important;
    background: #BDBDBD;
  }
  .search-input ::v-deep .v-input__slot {
    min-height: 36px !important;
    font-size: 14px !important;
  }
  .search-input ::v-deep .v-label {
    top: 9px !important;
  }
</style>
