<template>
  <v-container class="">
    <v-row>
      <v-col
        v-if="!nextStep"
        cols="12"
        md="9"
        sm="12"
        class="mx-auto d-flex flex-row justify-center"
      >
        <div class="numberSectionHeader">
          <div style="width: 100%; display: flex; justify-content: end; margin-bottom: 24px;">
            <div>
              <v-btn
                color="primary"
                class="text-capitalize confirm-btn"
                block
                @click="logoutUser"
              >
                Log out
              </v-btn>
            </div>
          </div>
          <div>
            <h4 style="min-width: 343px !important; height: 80px" class="display-1 dark--text">Select a FreeFone number</h4>
            <p class="mt-2 fz-16 line-h-20 font-weight-regular" style="color: #828282;">
              Look for Number by city or area code based on availability.<!--  You must have an existing
              US-based mobile or landline phone number to qualify. -->
              <!-- <span class="skip" @click="nextStep = true" >Skip this</span> -->
            </p>
          </div>
          <div
            v-if="errorMessage"
            class="pa-4 d-flex align-center mb-3"
            style="background-color: #EB575733;"
          >
            <v-icon size="16px" color="#EB5757" class="mr-3">mdi-alert</v-icon>
            <span>{{ errorMessage }}</span>
          </div>
          <v-autocomplete
            v-model="select"
            :loading="loading"
            :items="statesComputed"
            :search-input.sync="search"
            no-filter
            flat
            outlined
            return-object
            item-value="npa"
            prepend-inner-icon="mdi-magnify"
            label="Search by city or area zone"
            @focus="search = null"
          >
            <template v-slot:selection="data">
              <span>{{ data.item.city }}, {{ data.item.state }}</span>
            </template>
            <template v-slot:append>
              <v-icon class="" color="#5F6368">
                mdi-keyboard
              </v-icon>
            </template>
            <template v-slot:item="data">
              <template v-if="(typeof data.item) !== 'object'" >
                <v-list-item-content>{{data.item}}</v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content class="d-block">
                  <v-list-item-title class="d-inline-block mb-1">{{data.item.city}}</v-list-item-title>
                  <v-list-item-title class="d-inline-block mb-1 mr-2">,</v-list-item-title>
                  <v-list-item-title class="d-inline-block mb-1">{{data.item.state}}</v-list-item-title>
                  <v-list-item-title v-if="data.item.multipleNPA" class="d-inline-block mb-1 ml-2">({{data.item.npa}})</v-list-item-title>
                  <v-list-item-title v-else class="d-inline-block mb-1 ml-2">({{data.item.npa[0]}})</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            <template v-if="!allStatesLoaded" v-slot:append-item>
              <v-list-item-content class="d-flex align-center justify-center ma-0">
                <v-btn class="text-none" depressed @click="getCities(true)">Show all</v-btn>
              </v-list-item-content>
            </template>
            <template v-slot:no-data>
              <v-list-item-content v-if="loading" class="d-flex align-center justify-center">
                <v-progress-circular
                  :size="40"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-list-item-content>
              <v-list-item-content v-else class="d-flex align-center ma-0 pl-4">
                No data available
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <!-- <v-row v-if="numberNotFound" class="my-5 d-flex justify-center">
            <p>No numbers available</p>
          </v-row> -->

          <div v-if="numbersShow.length" class="mt-4">
            <v-autocomplete
              v-model="number"
              :disabled="isUpdating"
              :items="numbersShow"
              color="blue-grey lighten-2"
              label="Select"
              item-text="name"
              item-value="name"
            >
              <template v-slot:item="data">
                <template>
                  <v-icon class="mr-5">mdi-phone</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item}}</v-list-item-title>
                    <v-list-item-subtitle
                      class="caption"
                    >{{select.city}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <span class="numberSelect">Select</span>
                </template>
              </template>
            <template v-if="!allNumbersLoaded" v-slot:append-item>
              <v-list-item-content class="d-flex align-center justify-center ma-0">
                <v-btn class="text-none" depressed @click="getNumbers(select, true)">Show all</v-btn>
              </v-list-item-content>
            </template>
            </v-autocomplete>
            <v-btn
              @click="verifyStore"
              color="primary"
              class="mt-2 text-none w-100 next-button"
              :disabled="!number"
              depressed
            >
              Next
            </v-btn>
          </div>
        </div>
      </v-col>

      <v-col
        v-else
        cols="12"
        class="mx-auto d-flex flex-column flex-wrap align-center"
      >
        <div class="w-100 d-flex align-center" style="max-width:680px;margin-top:60px">
          <div class="d-flex justify-center w-100">
            <div v-if="false" class="numberSection mr-0">
              <h4 class="display-1 dark--text">You selected {{ number }}</h4>
              <p class="body-1 mt-2 text-mobile-center">
                To complete setup and start using FreeFone, you need to verify
                your existing phone number
              </p>
              <v-btn
                @click="verifyStore"
                color="primary"
                class="mt-2 text-none next-button"
                depressed
              >
                Verify
              </v-btn>
            </div>

            <div class="numberSection text-center mr-0">
              <v-img
                :src="require('../assets/svg/verify-success.svg')"
                contain
                width="40"
                height="40"
                max-width="min-content"
                class="my-6 mx-auto"
              />
              <h2 class="dark--text verifySuccess mb-4">Great!</h2>
              <div class="dark--text" style="font-size: 20px">You selected <span class="font-weight-bold">{{ number }}</span></div>
              <v-btn
                @click="redirectHandler"
                color="primary"
                class="mt-12 text-none next-button"
                depressed
              >
                Proceed
              </v-btn>
            </div>
          </div>
          <div class="d-flex justify-start mt-12 magazine-block">
           <!--  <v-img
              :src="require('../assets/png/Rectangle32small.png')"
              contain
              width="274"
              max-width="274"
              class="mr-2"
            />
            <v-img
              :src="require('../assets/png/Rectangle31small.png')"
              contain
              width="274"
              max-width="274"
            /> -->
          </div>
        </div>
      </v-col>

      <!--<v-col md="3" sm="12" class="pt-0">
         <v-img
          :src="require('../assets/png/Rectangle31.png')"
          contain
          width="360"
          class="mb-2"
        />
        <v-img
          :src="require('../assets/png/Rectangle32.png')"
          contain
          width="360"
        />
      </v-col> -->
    </v-row>
    <div v-if="loadingCircle" class="progress-circular">
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SelectNumber',
  data: () => {
    return {
      friends: ['Sandra Adams', 'Britta Holt'],
      isUpdating: false,
      loading: false,
      states: [],
      search: null,
      select: null,
      nextStep: false,
      verify: false,
      number: '',
      numberNotFound: false,
      numbersShow: {
        number: '',
        city: ''
      },
      loadingCircle: false,
      redirecting: false,
      errorMessage: '',
      allStatesLoaded: false,
      allNumbersLoaded: false,
      availableStatesForFreeUser: ['NY']
    }
  },
  computed: {
    ...mapGetters(['userProfile']),
    isFreeUser () {
      return this.userProfile.is_free_user
    },
    getStates () {
      if (this.isFreeUser) {
        return this.states.filter(item => this.availableStatesForFreeUser.includes(item.state))
      }
      return this.states
    },
    statesComputed () {
      if (!this.search) return this.getStates
      if (!isNaN(+this.search?.trim())) {
        const _npa = this.search.toString().trim()
        return this.getStates.filter(item => item._npa.includes(_npa))
      }
      if (this.search?.length === 2) {
        const _search = this.search?.toLowerCase()
        const byState = this.getStates.filter(item => item._state === _search)
        const byRestCountries = this.getStates.filter(item => item._state !== _search && item._city.includes(_search))
        return [...byState, ...byRestCountries]
      }
      const _search = this.search?.toString()?.toLowerCase()
      return this.getStates.filter(item => {
        return item.fullName.includes(_search) || item._npa.includes(_search)
      })
      // return this.states.filter(item => item.city?.toLowerCase().includes(this.search.toLowerCase()) ||
      //        item.state?.toLowerCase().includes(this.search.toLowerCase()) ||
      //        item.npa.toString().includes(this.search.toString()))
    }
  },
  watch: {
    select(val) {
      if (val) {
        return this.getNumbers(val)
      }
      this.numbersShow = []
      this.number = ''
      return []
    },
    search (val) {
      if (val && !this.allStatesLoaded) this.getCities(true)
    }
  },
  async created() {
    await this.checkNumber()
    await this.getCities(this.isFreeUser)
  },
  methods: {
    ...mapActions('numbers', ['assignNumber', 'getAvailableNumbers', 'getAvailableNpa']),
    ...mapActions(['getUserProfile', 'showAlert', 'logout']),
    async logoutUser() {
      try {
        await this.logout()
      } catch (e) {
        throw new Error(e)
      }
    },
    async verifyStore() {
      try {
        this.loadingCircle = true
        const data = await this.assignNumber(this.number)
        if (!data) return
        await this.getUserProfile()
        this.nextStep = true
        // this.verify = true
        setTimeout(() => {
          this.redirectHandler()
        }, 15000)
      } catch (err) {
        this.nextStep = false
        this.number = ''
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.errorMessage = error
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loadingCircle = false
      }
    },
    redirectHandler () {
      if (this.redirecting) return
      this.redirecting = true
      this.redirect()
    },
    async redirect () {
      try {
        if (this.userProfile.number) {
          this.loadingCircle = false
          this.$router.push({ name: 'Calls' })
          return
        }
        this.loadingCircle = true
        await this.getUserProfile()
        setTimeout(() => {
          this.redirect()
        }, 5000)
      } catch (err) {
        throw new Error(err)
      }
    },
    async getNumbers(val, loadAll = false) {
      if (!val) return
      try {
        this.loading = true
        const params = {
          npa: Array.isArray(val.npa) ? val.npa[0] : val.npa
        }
        if (loadAll) {
          params.take = 99
          this.allNumbersLoaded = true
        }
        // const npa = npaxx.toString().slice(0, 3)
        const data = await this.getAvailableNumbers(params)
        this.numbersShow = data
        // this.numbersShow.city = this.search
        this.numberNotFound = data.length === 0
      } catch (err) {
        this.numberNotFound = true
        throw new Error(err)
      } finally {
        this.loading = false
        this.errorMessage = this.numberNotFound ? 'No numbers available, please select another city/area' : ''
      }
    },
    async getCities(loadAll = false) {
      try {
        this.loading = true
        const params = {}
        if (loadAll) {
          params.take = 99999
          this.allStatesLoaded = true
        }
        const data = await this.getAvailableNpa(params)
        this.states = data.data
          .filter(item => item.city !== '0')
          .map(item => {
            const _state = item.state?.toLowerCase() || ''
            const _city = item.city?.toLowerCase() || ''
            return {
              ...item,
              fullName: `${_city}, ${_state}`,
              _npa: item.npa?.toString() || '',
              _state,
              _city
            }
          }).reduce((acc, state) => {
            if (!state.npa || state.npa.length === 1) acc.push(state)
            else {
              state.npa.forEach(npa => {
                acc.push({
                  ...state,
                  multipleNPA: state.npa,
                  npa
                })
              })
            }
            return acc
          }, [])
      } catch (err) {
        this.allStatesLoaded = false
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    customFilter (item, queryText, itemText) {
      return item.city?.toLowerCase().includes(queryText.toLowerCase()) ||
             item.state?.toLowerCase().includes(queryText.toLowerCase()) ||
             item.npa.toString().includes(queryText.toString())
    },
    async checkNumber () {
      if (!this.userProfile) await this.getUserProfile()
    }
  }
}
</script>

<style scoped lang="scss">
.numberSection {
  width: 100%;
  margin-top: 100px;
  max-width: 560px;
  margin-right: 205px;

  .skip {
    color: #2F80ED;
    cursor: pointer;
  }
}
.numberSectionHeader {
  width: 100%;
  margin-top: 60px;
  max-width: 560px;

  .skip {
    color: #2F80ED;
    cursor: pointer;
  }
}

.numberSelect {
  font-size: 16px;
  font-weight: 500;
  color: #5758b0;
}

.progress-circular {
  position: fixed;
  inset: 0;
  background-color: #0002;
  display: grid;
  place-items: center;
}

  @media (max-width: 600px) {
    .magazine-block {
      flex-direction: column;

      .v-image {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }
    }
    .numberSection {
      margin: 0;

      h4 {
        text-align: center;
      }

      button {
        width: 100%;
      }
    }
    .v-application .justify-end {
       justify-content: unset !important;
    }

    .text-mobile-center {
      text-align: center;
    }

    .next-button {
      position: fixed !important;
      left: 15px;
      right: 15px;
      width: calc(100vw - 24px) !important;
      bottom: 30px;
    }
  }
</style>
