<template>
  <v-col cols="12">
    <p>Security</p>
    <v-card elevation="0" outlined>
      <v-card-text class="d-flex align-center justify-space-between">
        <div>
          <h4 class="dark--text font-weight-medium fz-16">Filter spam</h4>
          <v-subheader class="px-0"
            >Calls, messages and voicemail
          </v-subheader>
        </div>
        <v-switch
          v-model="profile.filter_spam"
          :input-value="profile.filter_spam"
          @change="(val) => profileUpdate('filter_spam', val, 'Filter spam')"
        ></v-switch>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'settingsSecurity',
  props: ['profileData'],
  data() {
    return {
      profile: {
        filter_spam: false
      }
    }
  },
  methods: {
    ...mapActions(['editUserProfile', 'showAlert']),
    async profileUpdate(key, value, message = '') {
      try {
        await this.editUserProfile({ [key]: value })
        // this.showAlert({ message: message + (value ? ' enabled' : ' disabled') })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    }
  },
  watch: {
    profileData(newVal) {
      if (newVal) {
        this.profile = this.profileData
      }
    }
  }
}
</script>
