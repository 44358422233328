<template>
  <v-col cols="12">
    <p>Do not disturb</p>
    <v-card elevation="0" outlined>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Do not disturb
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >Send calls to voicemail
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            @change="doNotDisturbHandler"
            v-model="profile.do_not_disturb"
            :input-value="profile.do_not_disturb"
          ></v-switch>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'settingsNotDisturb',
  props: ['profileData'],
  data: () => {
    return {
      profile: {
        do_not_disturb: false
      }
    }
  },
  methods: {
    ...mapActions(['donotDisturb', 'showAlert']),
    async doNotDisturbHandler(value) {
      try {
        await this.donotDisturb(value)

        this.profile.do_not_disturb = value

        // this.showAlert({ message: 'Do not disturb ' + (value ? 'enabled' : 'disabled') })
      } catch (err) {
        this.profile.do_not_disturb = !value
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    }
  },
  watch: {
    profileData(newVal) {
      if (newVal) {
        this.profile = this.profileData
      }
    }
  }
}
</script>
